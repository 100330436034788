import httpHelper from "@/helpers/httpHelper";

const luckyWheelService = {
    async get(data) {
        return await httpHelper.get("/WebApi/LuckyWheel/Get", data);
    },
    async spin(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/LuckyWheel/Spin", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
};

export default luckyWheelService;
