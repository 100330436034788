<template>
  <div class="page page-account">
    <b-col class="py-3">
      <GameHeader :page-name="$t('Lucky wheel')"/>
      <RowLine/>
      <div class="game-feature">
        <h1 class="feature-title">{{ $t("Lucky wheel") }}</h1>
        <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.idServer" :options="servers" value-field="id" text-field="name"
                        :disabled="loading || formData.idGame < 1"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.idRole" :options="roles" value-field="id" text-field="name"
                        :disabled="loading || formData.idServer < 1"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <div class="d-flex justify-content-between" style="gap: 10px">
              <b-button style="flex: 1" v-for="(button, index) in moneyTypes" :key="index" :variant="formData.moneyType == button.id ? 'primary' : 'outline-secondary'" class="btn-rounded" @click="formData.moneyType=button.id">
                {{button.name}}
                <small class="d-block" style="font-size: 12px">({{ $n(button.times) }} {{$t("times")}})</small>
              </b-button>
            </div>
          </b-form-group>
          <div class="wheel-container">
            <div class="wheel-wrapper">
              <div class="wheel-pointer" @click="onSubmit()">
                {{ $t("Spin") }}
              </div>
              <div class="wheel-bg-container">
                <div
                    class="wheel-bg"
                    :class="{freeze: freeze}"
                    :style="`transform: rotate(${wheelDeg}deg)`"
                >
                  <div class="prize-list">
                    <div
                        class="prize-item-wrapper"
                        v-for="(item,index) in items"
                        :key="index"
                    >
                      <div
                          class="prize-item"
                          :style="`transform: rotate(${(360/ totalItems) * index}deg)`"
                      >
                        <div class="prize-name">
                          {{ $n(item.quantity) }}
                          <div>{{ item.goodName }}</div>
                        </div>
                        <div class="prize-icon">
                          <img :src="item.imageUrl.getUrl()">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-form-group class="pt-2">
            <div class="font-weight-bold">
              1. Diamonds are the most valuable currency in the game, used to buy most of the in-game items.
            </div>
            <div>
              2. After buying diamonds will be added directly to the number of diamonds in your account in the game.
            </div>
            <div class="font-weight-bold text-danger">
              3. Buying diamonds will increase VIP points and deposit-related events (For example, accumulative events,
              first deposit...)
            </div>
            <div>
              4. Once purchased, you will not be able to return, please consider and choose the right character to buy!
            </div>
          </b-form-group>
        </ValidationObserver>
        <LoadingModal :show="loading"/>
      </div>
    </b-col>
  </div>
</template>

<script>
import GameHeader from "@/components/headers/GameHeader";
import serverGameService from "@/services/serverGameService";
import luckyWheelService from "@/services/luckyWheelService";
import roleService from "@/services/roleService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "Index",
  components: {GameHeader},
  data() {
    return {
      loading: false,
      servers: [{
        id: 0,
        name: this.$t("Select a server")
      }],
      roles: [{
        id: 0,
        name: this.$t("Select a role")
      }],
      items: [],
      moneyTypes: [],
      totalItems: 0,
      freeze: false,
      rolling: false,
      wheelDeg: 0,
      formData: {
        idGame: 0,
        idServer: 0,
        idRole: 0,
        moneyType: 'money',
      }
    }
  },
  created() {
    this.$bridge.$on("setCurrentGame", this.onSetGame);
  },
  beforeDestroy() {
    this.$bridge.$off("setCurrentGame", this.onSetGame);
  },
  methods: {
    onSetGame(game) {
      if (!game) {
        this.$gotoHomePage();
        return;
      }

      this.formData.idGame = game.id;
      this.loadItems(game.id);
    },
    async loadItems(idGame) {
      this.loading = true;
      const response = await luckyWheelService.get({
        idGame: idGame
      });

      if (!response) {
        this.loading = false;
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        await this.$gotoHomePage();
        return;
      }

      if (response.error) {
        this.loading = false;
        await dialogHelper.alert(response.message);
        await this.$gotoHomePage();
        return;
      }

      this.items = response.data.items;
      this.totalItems = response.data.total;
      this.moneyTypes = response.data.moneyTypes;
      await this.loadServers(idGame);
    },
    async loadServers(idGame) {
      let servers = [{
        id: 0,
        name: this.$t("Select a server")
      }];

      const response = await serverGameService.getAllByGame(idGame);
      this.loading = false;
      if (!response)
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
      else if (response.error)
        await dialogHelper.alert(response.message);
      else
        servers = [...servers, ...response.data];

      this.servers = servers;
      this.formData.idServer = servers.length > 1 ? servers[1].id : 0;
    },
    setWheelDeg(index) {
      const {wheelDeg, totalItems} = this;
      if (typeof index === "undefined") {
        index = Math.floor(Math.random() * totalItems);
        console.log(index)
      }

      this.wheelDeg =
          wheelDeg -
          wheelDeg % 360 +
          6 * 360 +
          (360 - 360 / totalItems * index);
    },
    async onSubmit() {
      if (this.rolling)
        return;

      this.rolling = true;
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;

      await this.$recaptchaLoaded();
      const response = await luckyWheelService.spin(this.formData, await this.$recaptcha('spinLuckyWheel'))

      if (!response) {
        this.rolling = false;
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        this.rolling = false;
        await dialogHelper.alert(response.message);
        return;
      }

      this.setWheelDeg(response.data.index);
      setTimeout(async () => {
        await dialogHelper.alert(response.message + "<br/>" + this.$t("Congratulations on getting {{quantity}} {{name}}", {
          quantity: response.data.quantity,
          name: response.data.goodName
        }), "success");
        this.moneyTypes = response.data.moneyTypes || response.data.getMoneyTypes;
        this.rolling = false
      }, 5000);
    }
  },
  watch: {
    "formData.idServer": async function (newValue) {
      let roles = [{
        id: 0,
        name: this.$t("Select a role")
      }];

      if (newValue > 0) {
        this.loading = true;
        const response = await roleService.getRolesByServer(newValue);
        this.loading = false;
        if (!response)
          await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        else if (response.error)
          await dialogHelper.alert(response.message);
        else
          roles = [...roles, ...response.data];
      }

      this.roles = roles;
      this.formData.idRole = roles.length > 1 ? roles[1].id : 0;
    },
  }
}
</script>

<style scoped lang="scss">
.wheel-container {
  display: block;
  max-width: 400px;
  margin: 0 auto 15px auto;

  .wheel-wrapper {
    display: block;
    height: 0;
    padding-bottom: 100%;
    position: relative;

    .wheel-pointer {
      width: 60px;
      height: 60px;
      border-radius: 1000px;
      background: yellow;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      line-height: 60px;
      z-index: 10;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        top: -32px;
        left: 50%;
        border-width: 0 8px 40px;
        border-style: solid;
        border-color: transparent transparent yellow;
        transform: translateX(-50%);
      }
    }

    .wheel-bg-container {
      position: absolute;
      top: 15px;
      left: 15px;
      bottom: 15px;
      right: 15px;
      display: block;
    }

    .wheel-bg {
      width: 100%;
      height: 100%;
      border-radius: 1000px;
      overflow: hidden;
      transition: transform 5s ease-in-out;
      background: #7eef97;

      &.freeze {
        transition: none;
        background: red;
      }
    }

    .prize-list {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
    }

    .prize-item-wrapper {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 50%;
    }

    .prize-item {
      width: 100%;
      height: 100%;
      transform-origin: bottom;

      .prize-name {
        padding: 20px 0 10px 0;
        font-size: 13px;

        div {
          font-size: 12px;
        }
      }

      .prize-icon img {
        width: 40px;
      }
    }
  }
}
</style>
